function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

// Show password on reset password page
$(document).on('click', '#show_passwords', function(){
  if ($(this).is(':checked')){
    $('.password').attr('type','text');
  }else{
    $('.password').attr('type','password');
  }
});
