/**
 * Turkmen translation for bootstrap-datepicker
 * N'Bayramberdiyev <nbayramberdiyev@outlook.com>
 */
;(function($){
	$.fn.datepicker.dates['tk'] = {
		days: ["Ýekşenbe", "Duşenbe", "Sişenbe", "Çarşenbe", "Penşenbe", "Anna", "Şenbe"],
		daysShort: ["Ýek", "Duş", "Siş", "Çar", "Pen", "Ann", "Şen"],
		daysMin: ["Ýe", "Du", "Si", "Ça", "Pe", "An", "Şe"],
		months: ["Ýanwar", "Fewral", "Mart", "Aprel", "Maý", "Iýun", "Iýul", "Awgust", "Sentýabr", "Oktýabr", "Noýabr", "Dekabr"],
		monthsShort: ["Ýan", "Few", "Mar", "Apr", "Maý", "Iýn", "Iýl", "Awg", "Sen", "Okt", "Noý", "Dek"],
		today: "Bu gün",
		monthsTitle: "Aýlar",
		clear: "Aýyr",
		weekStart: 1,
		format: "dd.mm.yyyy"
	};
}(jQuery));
