function init_backbone(Backbone, session_json) {
	FpciSaas.app = new Backbone.Marionette.Application();
  FpciSaas.app.addRegions({ mainRegion: "#app" });
  FpciSaas.app.addInitializer(function(options) {
  	FpciSaas.app.session = new FpciSaas.Models.Session(session_json);
  	var view = new FpciSaas.Views.SigninView({ model: FpciSaas.app.session });
  	FpciSaas.app.mainRegion.show(view);
  });
  FpciSaas.app.start();
}
window.init_backbone = init_backbone
