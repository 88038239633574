/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("jquery")
import $ from 'jquery';
global.$ = $;
global.jQuery = $;

window['_'] = require("packs/underscore.js");
require("packs/bootstrap/bootstrap.js");
require("packs/bootstrap/bootstrap-tooltip.js");
requireAll(require.context('./bootstrap/', true, /\.js$/ ));
require("bootstrap-timepicker");

// Select2
// =======
require("packs/select2");

// Backbone stack
// ==============
require("backbone");
require("packs/backbone.queryparams.js");
require("packs/backbone_rails_sync.js");
require("packs/backbone_datalink.js");
require("packs/backbone.memento.js");
require("packs/backbone.marionette.js");
require("packs/backbone.stickit.js");
require("packs/backbone-nested.js");
require("packs/backbone-validation");
require("packs/backbone.paginator.js");
require("backbone.cocktail");
require("packs/select2.js")
require('packs/date.format.js')
require("moment");
require('jshashes');
require("packs/init_backbone.js")
require('packs/spin.js');

require("packs/common.js");
require("packs/fpci_saas.coffee");
// App file for every page
// =======================
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('./foundation/', true, /\.js$/ ));
requireAll(require.context('./models/', true, /\.js$/));
requireAll(require.context('./collections/', true, /\.js$/));
requireAll(require.context('./mixins/', true, /\.js$/));
requireAll(require.context('./views/', true, /\.js$/));
requireAll(require.context('./test/models/', true, /\.js$/));
requireAll(require.context('./font/', true, /\.js$/));
requireAll(require.context('./test/collections/', true, /\.js$/));
requireAll(require.context('./test/views/', true, /\.js$/));
requireAll(require.context('./test/', true, /\.js$/));



import Backbone from 'backbone';
import Validation from 'packs/backbone-validation';
import Marionette from 'packs/backbone.marionette';
import * as _ from 'packs/underscore'
import { init_backbone } from 'packs/init_backbone';
import FpciSaas from 'packs/fpci_saas';
import Cocktail from 'backbone.cocktail';
import Hashes from 'jshashes';
import Spinner from 'spin';
import moment from 'moment';


import './application'
